import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { Text } from '../utils/Typography.jsx';
import { GreenBox } from '../utils/Utils.jsx';

import MenuItems from './MenuItems.jsx';

function Header({currPage, isMobile, isNarrow}) {
    const [isOpen, setIsOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(currPage);

    function MenuPopup() {
        return (
            <Box sx={{
                position: "fixed",
                top: "0",
                left: "0",
                height: "100vh",
                width: "100vw",
                zIndex: "100",
    
                backgroundColor: "secondary.main",
                opacity: "0.95",
    
                display: isOpen ? "flex" : "none",
            }}
                onClick={() => { setIsOpen(false) }}
            >
                <Stack spacing={0} sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    alignItems: "center",
                    justifyContent: "center",
    
                    width: isNarrow ? "70%" : "30%",
                    borderRadius: "0.5rem",
    
                    backgroundColor: "primary.background",
                }}
                    onMouseLeave={() => { setIsHovered(currPage) }} 
                >
                    <Box sx={{
                        position: "relative",
                        width: "80%",
                        marginY: "0.5rem",
                        paddingY: "0.5rem",
                        borderBottom: "0.1rem solid",
                        borderColor: "primary.main",
                    }}>
                        <Text glow title sx={{
                            float: "left",
                            fontSize: "1.6rem",
                            letterSpacing: "-0.05rem",
                            transform: "scaleY(1.2)",
                            translate: isMobile ? "0 0.5rem" : null,
                            paddingY: isMobile ? null : "0.5rem",
                        }}>
                            MENU
                        </Text>
                    </Box>
                    <MenuItems isHovered={isHovered} setIsHovered={setIsHovered} />
                </Stack>
            </Box>
        );
    }

    return (
        <Box sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "4rem",
            zIndex: "50",
        
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "secondary.main"
        }}>
            <Box sx={{
                padding: "1.5rem",
                width: isNarrow ? "100%" : "70%",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <GreenBox>
                    'LEN.CUSTOMS' INITIALIZED&nbsp;
                </GreenBox>
                <Menu className="glow" sx={{
                    color: "white",
                    fontSize: "2rem",
                }}
                    onClick={() => { setIsOpen(true) }}
                />
                <MenuPopup />
            </Box>
        </Box>
    );
}

export default Header;