import React from "react";
import { Box } from "@mui/material";
import { Text } from "../utils/Typography.jsx"; 
import theme from "../utils/Theme.jsx";

const palette = theme.palette;

function PageTitle({upperText, lowerText}) {
    return <Box sx={{
        padding: "0.5rem 2rem",
        marginBottom: "1rem",
    }}>
        <Text mokimono short sx={{
            fontSize: "1.5rem",
        }}>
            {upperText.toUpperCase()}
        </Text>
        {lowerText && <Text mokimono short color="transparent" sx={{
            WebkitTextStroke: "0.1rem" + palette.primary.main,
            fontSize: "1.5rem",
        }}>
            {lowerText.toUpperCase()}
        </Text>
        }
    </Box>;
}

export default PageTitle;