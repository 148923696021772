import React, { useState } from 'react';
import sha256 from "crypto-js/sha256";
import theme from '../utils/Theme.jsx';
import { Box, CssBaseline, ThemeProvider, TextField, FormControl, Button } from '@mui/material';
import UsePersistedState from '../utils/UsePersistedState.jsx';
import { getIsMobile } from '../utils/Utils.jsx';
import { isMobile } from 'react-device-detect';
import Header from './Header.jsx';
// import Footer from './Footer.jsx';
import { Text } from '../utils/Typography.jsx';

function Layout({currPage, children, ...props }) {
    const [tooSmall, setTooSmall] = useState(false);
    const [isNarrow, setIsNarrow] = useState(getIsMobile());

    window.addEventListener("resize", () => {
        setIsNarrow(getIsMobile());
        if (isNarrow) {
            setTooSmall(window.innerWidth < 350);
            console.log(window.innerWidth);
        }
    });

    const loginDisabled = true;

    const [password, setPassword] = UsePersistedState("phase2Password", "");
    const [loginFailed, setLoginFailed] = useState(false);
    const [passwordValue, setPasswordValue] = useState("");

    const hashedKey = "929c4438893590bb5ee0ba1f77e2fba3611458b562f0d1b5354aabda05ae0a3d";
    const loggedIn = password === hashedKey;

    const handleSubmit = () => {
        const hashed = sha256(passwordValue).toString();
        console.log(hashed);
        if (hashed === hashedKey) {
            setPassword(hashed);
            setPasswordValue("");
        }
        else {
            setPasswordValue("");
            setLoginFailed(true);
        }
    };

    const LoginPage = <Box sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "secondary.main",
    }}>
        <FormControl>
            <TextField
                variant="outlined" focused
                type="password" error={loginFailed}
                label="Password" value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit();
                    }
                }}
                sx={{
                    // set inner text colour
                    "& .MuiInputBase-input": { color: "white" },
                }}
            />
            <Button type="submit" label="Submit" onClick={handleSubmit}>Submit</Button>
        </FormControl>
    </Box>;

    // remove footer
    const Content = <>
        <Header currPage={currPage} isMobile={isMobile} isNarrow={isNarrow} />
        {children}
        {/* <Footer/> */}
    </>;

    const ContentWrapper = tooSmall ?
        <Box sx={{ // page wrapper
            padding: "2%"
        }}>
            <Text>
                Your screen is too small to view this website.
                Please resize your window or use a larger device.
            </Text>
        </Box>
        : isNarrow ?
            <Box sx={{ // content wrapper
                paddingTop: "4rem",
                paddingBottom: "2rem",
            }}>
                {Content}
            </Box>
            : 
            <Box sx={{ // page wrapper
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Box sx={{ // content wrapper
                    width: "70%",
                    paddingTop: "4rem",
                    paddingBottom: "2rem",
                }}>
                    {Content}
                </Box>
            </Box>;

    return (
        // wrapper
        <Box sx={{
            width: "100vw",
            height: "100vh",
            position: "relative",
        }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {loginDisabled || loggedIn ? ContentWrapper : LoginPage}
            </ThemeProvider>
        </Box>
    );
}

export default Layout;