import React, { useState } from 'react';
import Layout from '../components/Layout.jsx';
import PageTitle from '../components/PageTitle.jsx';
import { Box, Stack } from '@mui/material';
import { Text } from '../utils/Typography.jsx';
import { TopLeftPipe, LeftPipe, BottomLeftPipe } from '../components/Pipe.jsx';
import { GreenBox, getIsMobile } from '../utils/Utils.jsx';
import theme from '../utils/Theme.jsx';

function OrderPage() {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    window.addEventListener("resize", () => {
        setIsMobile(getIsMobile());
    });
    const palette = theme.palette;

    function Header({text}) {
        return <Box sx={{
            position: "relative",
            float: "left",
            width: "80%",
            marginX: "5%",
            paddingTop: "0.5rem",
            paddingBottom: "1rem",
            borderBottom: "0.1rem solid",
            borderColor: "primary.main",
        }}>
            <Text glow title sx={{
                fontSize: "1.6rem",
                float: "left",
                letterSpacing: "-0.05rem",
                transform: "scaleY(1.2)",
            }}>
                {text}
            </Text>
            <span style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "60%",
                height: "0.2rem",
                backgroundColor: palette.primary.main,
                boxShadow: `0 0 0.1rem ${palette.primary.main}`,
            }} />
        </Box>;
    }

    return (
        <Layout currPage="order">
            <PageTitle upperText="Order" lowerText={null} />

            <Stack direction={isMobile ? "column" : "row"} spacing={0} sx={{
                width: "100%",
                alignItems: isMobile ? "center" : "flex-start",
            }}>
                <Box padding="1rem" width={isMobile ? "100%" : "50%"}>
                    <Header text="HOW TO PURCHASE" />
                    <br/>
                    <Box sx={{ 
                        display: "flex",
                        width: "100%",
                        padding: "5%",
                        paddingTop: "1.5rem"
                    }}>

                        <Stack>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <TopLeftPipe/>
                                <GreenBox className="connect" whiteBox>
                                    &nbsp;PRICES & LEAD TIMES&nbsp;
                                </GreenBox>
                            </Box>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <LeftPipe/>
                                <Box sx={{
                                    paddingX: "1.75rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        Prices vary based on design complexity, number of parts painted and colours used. Lead times vary based on design complexity.
                                        More information will be provided in the google form below. 
                                    </Text>
                                </Box>
                            </Box>
                            
                            <Box position="relative" paddingLeft="15px">
                                <BottomLeftPipe/>
                                <GreenBox className="connect" whiteBox sx={{
                                    marginTop: "0.65rem",
                                }}>
                                    &nbsp;COMMISSION FORM&nbsp;
                                </GreenBox>
                                <Box sx={{
                                    paddingX: "1rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        Please fill in&nbsp;
                                        <a href="https://forms.gle/yM6EDFVrJqWLdySW7" target="_blank" rel="noreferrer"
                                            style={{ color: palette.primary.main }}
                                        >
                                            this form
                                        </a>
                                        &nbsp;and we will get back to you within 3-5 business days.<br/><br/>
                                        If you have not received a reply from us by then, it is likely because your customization choices have exceeded your budget.
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>

                    </Box>
                </Box>

                <Box padding="1rem" width={isMobile ? "100%" : "50%"}>
                    <Header text="DELIVERY & PAYMENT" />
                    <br/>
                    <Box sx={{ 
                        display: "flex",
                        width: "100%",
                        padding: "5%",
                        paddingTop: "1.5rem"
                    }}>

                        <Stack>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <TopLeftPipe/>
                                <GreenBox className="connect" whiteBox>
                                    &nbsp;DELIVERY&nbsp;
                                </GreenBox>
                            </Box>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <LeftPipe/>
                                <Box sx={{
                                    paddingX: "1.75rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        Singapore: Doorstep delivery or collection in-store<br/>
                                        International: Shipping via FedEx
                                    </Text>
                                </Box>
                            </Box>
                            
                            <Box position="relative" paddingLeft="15px">
                                <BottomLeftPipe/>
                                <GreenBox className="connect" whiteBox sx={{
                                    marginTop: "0.65rem",
                                }}>
                                    &nbsp;PAYMENT&nbsp;
                                </GreenBox>
                                <Box sx={{
                                    paddingX: "1rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        Singapore: Cash (only in-store), PAYNOW or PAYlah!<br/>
                                        International: PayPal or TT Bank Transfer
                                    </Text>
                                </Box>
                            </Box>
                        </Stack>

                    </Box>
                </Box>
            </Stack>
        </Layout>
    );
}

export default OrderPage;