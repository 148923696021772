import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

function ProductImages({imageList, type, name, sx}) {
    const [currImg, setCurrImg] = useState(0);
    let n_images = 0;
    
    const images = imageList.map((url, index) => {
        n_images++;
        return (
            <img
                key={index}
                src={`${process.env.PUBLIC_URL}/images/${type}/${url}.webp`}
                alt={name}
                style={{
                    width: "100%",
                    padding: "5%",
                    borderImage: url.slice(-1) === "v" ? `url(${process.env.PUBLIC_URL}/svg/longborder.svg)` : `url(${process.env.PUBLIC_URL}/svg/shortborder.svg)`,
                    borderImageSlice: "10 fill",
                    boxSizing: "border-box",
                    borderStyle: "solid",
                    display: index === currImg ? "block" : "none",
                }}
            />
        );
    })

    function TripleArrows({dir}) {
        const url = `url(${process.env.PUBLIC_URL}/svg/arrow.svg)`
        const colors = ["#B2EDD7", "#479676", "#255441"];
    
        function Arrow({num}) {
            const [ color, setColor ] = useState(dir === "left" ? num : 2 - num);
    
            useEffect(() => {
                const interval = setInterval(() => {
                    setColor((color + 1) % 3);
                }, 300);
    
                return () => clearInterval(interval);
            }
            , [color]);

            return <Box sx={{
                maskImage: url,
                backgroundColor: colors[color],
                height: "1.5rem",
                width: "1.2rem",
                marginX: "-0.2rem",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                transform: dir === "left" ? null : "rotate(180deg)",
            }} />;
        }
    
        return <Box sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
    
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
    
            left: dir === "left" ? 0 : null,
            right: dir === "left" ? null : 0,
        }}
            onClick={() => {
                setCurrImg((currImg + (dir === "left" ? -1 : 1) + n_images) % n_images);
            }}
        >
            <Arrow num={0} />
            <Arrow num={1} />
            <Arrow num={2} />
        </Box>;
    }

    return <Box sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: "1rem",
        ...sx,
    }}>
        {images}
        <TripleArrows dir="left" />
        <TripleArrows dir="right" />
    </Box>;
}

export default ProductImages;