import { createTheme } from "@mui/material/styles";


let defaultFont = {
    fontFamily: "BBManualBold",  // reads from css
    fontSize:  "4rem",
    fontSizeMobile:  "2rem",
    // letterSpacing: "1.5rem",
    lineHeight: "1",
};

const theme = createTheme({
    // colors
    palette: {
        primary: {
            main: "#B2EDD7", // green
            background: "#053D29", // black
        },
        secondary: {
            main: "#070707", // black
            light: "#323937", // grey
            background: "#053D29", // dark green
        },
        error: {
            main: "#e74c3c", // red
        },
        disabled: {
            main: "##424242", //grey
        },
        text: {
            secondary: "#FF9900", // orange
            disabled: "#424242", // grey
        },
    },

    // fonts
    typography: {
        defaultFont: {
            ...defaultFont,
        }
    },

    // defaults
    components: {
        MuiCssBaseline: { // OVERRIDE DEFAULT FONT
            styleOverrides: (themeParam) => `
                html, body {
                    background-color: ${themeParam.palette.secondary.main} !important;
                    width: 100vw !important;
                    height: 100vh !important;
                }

                glow {
                    filter: drop-shadow(0 0 0.2rem ${themeParam.palette.primary.main});
                }
            `
        }
    }
});

export default theme;