import React from 'react';
import { Typography } from '@mui/material';
import theme from "./Theme.jsx";
import { isMobile } from 'react-device-detect';

function Text({
    // weights 
    bold=false, mokimono=false, gakuran=false,
    // alignment
    center=false, right=false, justify=false, Ycenter=false, short=false,
    // colours
    color="primary.main", font="default", glow=false, disabled=false,
    // others
    resize=false, title=false, sx={}, ...props}) {

    if (mokimono) {
        font = "MokiMono"
    } else if (gakuran) {
        font = "Gakuran"
    } else if (font === "default") {
        font = theme.typography.defaultFont.fontFamily;
        sx["letterSpacing"] = "-0.05rem";
    }

    if (bold) {
        font = font.replace("Bold", "SL");
    }
    // alignment
    if (center) {
        sx["textAlign"] = "center";
    } else if (right) {
        sx["textAlign"] = "right";
    } else if (justify) {
        sx["textAlign"] = "justify";
    } else {
        sx["textAlign"] = "left";
    }

    if (Ycenter) {
        sx["transform"] = "translateY(-25%)";
    }

    // short
    if (short) {
        sx["lineHeight"] = "1";
    }

    // glow
    if (glow) {
        sx["textShadow"] = `0 0 1rem ${theme.palette.primary.main}`;
    }

    // color
    if (color === "secondary") {
        sx["color"] = theme.palette.text.secondary;
    } else if (color === "disabled") {
        sx["color"] = theme.palette.text.disabled;

    } else {
        sx["color"] = color;
    }
    
    // mobile font size
    if (resize && sx["fontSize"] === undefined) {
        sx["fontSize"] = isMobile ? "1rem" : "1.2rem";
    }
    if (resize && isMobile) {
        sx["transform"] = "translateY(0.05rem)";
    }
    if (title) {
        sx["transform"] = "translateY(0.05rem)";
        sx["lineHeight"] = isMobile ? "1" : "0.5";
    }

    return <FontText fontFamily={font} sx={sx} {...props}></FontText>;
}

function FontText({fontFamily, variant, sx={}, paragraph=true, children, ...props}) {
    return <Typography
        sx={{
            fontFamily: fontFamily,
            whiteSpace: paragraph ? "pre-line" : null,
            // whiteSpace: "pre-wrap",
            ...sx
        }}
        {...props}
    >
        {children}
    </Typography>;
}

export { Text, FontText };