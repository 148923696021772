import React from 'react';
import { Box } from '@mui/material';

const width = 10;
const left = width / 2; 

function TopLeftPipe() {
    return <Box sx={{
        border: "1px solid",
        borderColor: "primary.main",

        borderRight: "none",
        borderBottom: "none",
        borderTopLeftRadius: "0.3rem",
        
        position: "absolute",
        top: 0, left: left + "px",
        width: width + "px", height: "100%",
        boxSizing: "border-box",
        marginTop: "0.68rem"
    }} />;
}

function LeftPipe() {
    return <Box sx={{
        borderLeft: "1px solid",
        borderColor: "primary.main",

        position: "absolute",
        top: 0, left: left + "px",
        width: width + "px", height: "100%",
        boxSizing: "border-box",
    }} />;
}

function BottomLeftPipe() {
    return <Box sx={{
        border: "1px solid",
        borderColor: "primary.main",

        borderRight: "none",
        borderTop: "none",
        borderBottomLeftRadius: "0.3rem",
        
        position: "absolute",
        top: 0, left: "5px",
        width: "10px", height: "1.5rem",
        boxSizing: "border-box",
    }} />;
}

export { TopLeftPipe, LeftPipe, BottomLeftPipe }