import React from 'react';
import { Box } from '@mui/material';
import { Text } from '../utils/Typography.jsx';

import theme from '../utils/Theme.jsx';

const items = {
    "Mice Showcase": "mouse",
    "Keyboards Showcase": "keyboard",
    "Contact Us": "contact-us",
    "Order": "order",
}

function MenuItems({currPage, isHovered, setIsHovered}) {
    return <>
        {Object.keys(items).map((item, i) => {
            return <MenuItem item={item} currPage={currPage} key={i}
             isHovered={isHovered} setIsHovered={setIsHovered} />
        })}
    </>
}

function MenuBorder() {
    const borderColor = theme.palette.primary.main;
    const borderWidth = "0.1rem";
    const borderLength = "0.5rem";

    return <Box sx={{
        position: "absolute",
        top: "-15%",
        left: "10%",

        width: "80%",
        height: "130%",

        backgroundImage: `
            linear-gradient(to right, ${borderColor} ${borderLength}, transparent ${borderLength} calc(100% - ${borderLength}), ${borderColor} calc(100% - ${borderLength})),
            linear-gradient(to bottom, ${borderColor} ${borderLength}, transparent ${borderLength} calc(100% - ${borderLength}), ${borderColor} calc(100% - ${borderLength})),
            linear-gradient(to bottom, ${borderColor} ${borderLength}, transparent ${borderLength} calc(100% - ${borderLength}), ${borderColor} calc(100% - ${borderLength})),
            linear-gradient(to right, ${borderColor} ${borderLength}, transparent ${borderLength} calc(100% - ${borderLength}), ${borderColor} calc(100% - ${borderLength}))
        `,
        backgroundSize: `100% ${borderWidth}, ${borderWidth} 100%, ${borderWidth} 100%, 100% ${borderWidth}`,
        backgroundPosition: "top left, top left, top right, bottom left",
        backgroundRepeat: "no-repeat",
    }} />

}

function MenuItem({item, currPage, isHovered, setIsHovered}) {

    return <Box sx={{
        position: "relative",

        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginY: "1rem",
        paddingX: "5%",

        // backgroundColor: currPage === item ? "#05271B" : "transparent",
        backgroundColor: isHovered === item ? "#05271B" : "transparent",
        cursor: "pointer",
    }}
        onClick={() => {
            window.location.href = `/${items[item]}`;
        }}
        onMouseEnter={() => setIsHovered(item)}
    >
        <Text short Ycenter resize>
            {item.toUpperCase()}
        </Text>
        { isHovered === item && <MenuBorder /> }
    </Box>
}

export default MenuItems;