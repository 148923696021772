import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';

// Pages
import LandingPage from "./pages/LandingPage.jsx";
// import Commissions from "./pages/Commissions.jsx";
import ProductMenu from "./pages/ProductMenu.jsx";
import ProductShowcase from "./pages/ProductShowcase.jsx";
import Contact from "./pages/Contact.jsx";
import OrderPage from "./pages/OrderPage.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/commissions" element={<Commissions />} /> */}
          <Route path="/:type" element={<ProductMenu />} />
          <Route path="/:type/:name" element={<ProductShowcase />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/order" element={<OrderPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
