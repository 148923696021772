import React from "react";
import Layout from "../components/Layout.jsx";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";

// import { Text } from "../utils/Typography.jsx";
import PageTitle from "../components/PageTitle.jsx";
import products from "../products.json";

function ProductShowcase() {
    const params = useParams();
    const type = params.type.toLowerCase();
    
    const currPage = type === "mouse" ? "Mice Showcase" : "Keyboards Showcase";
    const [isHover, setHover] = React.useState(null);

    const ProductGrid = Object.keys(products[type]).map((key, index) => {
        const product = products[type][key];
        const url = product.images[0];
        const name = product.name;

        return (
            <Grid item xs={6} md={3} key={index}
                container direction="column" alignContent="center"
                sx={{ cursor: "pointer" }}
                onClick={() => window.location.href = `/${type}/${product.tag}`}
            >
                <Box sx={{ // image wrapper
                    width: "100%",
                    aspectRatio: "1",
                    overflow: "hidden",
                    position: "relative",

                    // border
                    borderWidth: "0.1rem",
                    borderStyle: "solid",
                    borderRadius: "0.5rem",
                    borderColor: isHover === index ? "primary.main" : "transparent",
                    boxSizing: "border-box",
                }}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(null)}
                >
                    <img src={`${process.env.PUBLIC_URL}/images/${type}/${url}.webp`}
                        alt={name} 
                        style={{
                            borderRadius: "0.5rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "95%",
                            height: "95%",
                            objectFit: "cover",
                    }} />
                </Box>
                {/* <Box paddingY="0.5rem">
                    <Text sx={{
                        fontSize: "1.2rem",
                        lineHeight: "0.5",
                        letterSpacing: "-0.05rem",
                        transform: "scaleY(1.2)",
                    }}>
                        {(index+1).toString().padStart(2, "0")}_{name}
                    </Text>
                </Box> */}
            </Grid>
        );
    });

    return (
        <Layout currPage={currPage}>
            <PageTitle upperText="custom" lowerText={type} />
            <Box paddingX="1.5rem">
                <Grid container spacing={2}
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    {ProductGrid}
                </Grid>
            </Box>
        </Layout>
    );
}
export default ProductShowcase;