import React, { useState } from "react";
import Layout from "../components/Layout.jsx";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";

import { Text } from "../utils/Typography.jsx";
import PageTitle from "../components/PageTitle.jsx";
import ProductImages from "../components/ProductImages.jsx";
import { TopLeftPipe, LeftPipe, BottomLeftPipe } from "../components/Pipe.jsx";
import { Plus, GreenBox, getIsMobile } from "../utils/Utils.jsx";
import theme from "../utils/Theme.jsx";
import products from "../products.json";

const palette = theme.palette;

function ProductShowcase() {
    const params = useParams();
    const type = params.type.toLowerCase();
    const name = params.name.toLowerCase();
    const product = products[type][name];

    const [isMobile, setIsMobile] = useState(getIsMobile());
    window.addEventListener("resize", () => {
        setIsMobile(getIsMobile());
    });

    const pages = {
        mouse: "Mice Showcase",
        keyboard: "Keyboards Showcase",
    }

    function ProductName() {
        return <Box sx={{
            position: "relative",
            float: "left",
            width: "80%",
            marginX: "5%",
            paddingY: "0.5rem",
            borderBottom: "0.1rem solid",
            borderColor: "primary.main",
        }}>
            <Text gakuran glow sx={{
                float: "left",
                transform: "scaleY(1.3)",
                marginRight: "0.25rem",
            }}>
                {product.jp_name !== "" ? product.jp_name : ""}
            </Text>
            <Text glow title sx={{
                fontSize: "1.6rem",
                float: "left",
                letterSpacing: "-0.05rem",
                transform: "scaleY(1.2)",
            }}>
                {product.name}
            </Text>
            <span style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "60%",
                height: "0.2rem",
                backgroundColor: palette.primary.main,
                boxShadow: `0 0 0.1rem ${palette.primary.main}`,
            }} />
        </Box>;
    }
    
    const currPage = pages[type];

    let specs = "";
    for (const [key, value] of Object.entries(product.specifications)) {
        specs += `${key.toUpperCase() }: ${value.toUpperCase()}\n`;
    }
    let hardware = "";
    for (const [key, value] of Object.entries(product.hardware)) {
        hardware += `${key.toUpperCase() }: ${value.toUpperCase()}\n`;
    }

    const [ hardwareDisplay, setHardwareDisplay ] = useState("none");

    return (
        <Layout currPage={currPage}>
            <PageTitle upperText="custom" lowerText={type} />

            <Stack direction={isMobile ? "column" : "row"} spacing={0} sx={{
                width: "100%",
                alignItems: isMobile ? "center" : "flex-start",
            }}>
                <ProductImages imageList={product.images} type={type} name={name}
                    sx={{ width: isMobile ? "100%" : "50%"}}
                />

                <Box padding="1rem" width={isMobile ? "100%" : "50%"}>
                    <ProductName/>
                    <br/>
                    <Box sx={{ 
                        display: "flex",
                        width: "100%",
                        padding: "5%",
                        paddingTop: "1.5rem"
                    }}>

                        <Stack>
                            {/* SPECS */}
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <TopLeftPipe/>
                                <GreenBox className="connect" whiteBox>
                                    &nbsp;SPECIFICATIONS&nbsp;
                                </GreenBox>
                            </Box>
                            <Box display="flex" position="relative" paddingLeft="15px">
                                <LeftPipe/>
                                <Box sx={{
                                    paddingX: "1.75rem",
                                    paddingY: "0.5rem",
                                }}>
                                    <Text resize>
                                        {specs}
                                    </Text>
                                </Box>
                            </Box>
                            
                            {/* HARDWARE */}
                            <Box position="relative" paddingLeft="15px">
                                <BottomLeftPipe/>
                                <Box sx={{
                                    backgroundImage: hardwareDisplay === "none"
                                        ? "none"
                                        : `url(${process.env.PUBLIC_URL}/svg/hardware_bg.svg)`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    width: "fit-content",
                                    paddingY: "0.5rem",
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        position: "relative",
                                    }}
                                    onClick={() => {
                                        setHardwareDisplay(hardwareDisplay === "none" ? "block" : "none");
                                    }}>
                                        <Plus/>
                                        <Text short Ycenter resize>
                                            [ HARDWARE ]
                                        </Text>
                                    </Box>
                                    <Box sx={{
                                        display: hardwareDisplay,
                                        paddingX: "2.5rem",
                                        paddingBottom: "1rem",
                                    }}>
                                        <Text resize>
                                            {hardware}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>

                    </Box>
                </Box>
            </Stack>

        </Layout>
    );
}
export default ProductShowcase;